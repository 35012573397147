$(document).on(GLOBALS.readyEvent, function () {

    var checkDate = function (el) {
        var that = $(el);
        var div = that.closest('div');
        var from = new Date(div.attr('data-window-start'));
        var upto = new Date(div.attr('data-window-end'))
        var date = new Date(el.value);
        if ((date >= from) && (date <= upto)) {
            div[0].className = '';
            div.attr('data-error-message', '')
        } else {
            div[0].className = 'error border border-red-700';
            div.attr('data-error-message', 'not within reporting window')
        }
    }

    $('#events-editable').on('change', function (e) {
        if (e.target.name == 'event[start_date]') {
            checkDate(e.target);
        }
        var $f = $(e.target.form);
        $.ajax({
            url: $f.attr('action'),
            type: $f.attr('method'),
            dataType: 'html',
            data: $f.serialize(),
            success: function (data) {
                // console.log('received', data)
            },
            error: function (xhr, err) {
                console.log('error', err)
            }
        });
    });

    var dp = $(".datepicker")
    dp.datepicker({
        numberOfMonths: parseInt(dp.attr('data-months')),
        dateFormat: 'dd/mm/yy',
        showButtonPanel: false,
        maxDate: dp.attr('max'),
        minDate: dp.attr('min')
    });


    $(document)
        .offOn('period-selected', '#events-index .period-select, #events-new .period-select', function (event, periodId, existing) {
                var path = document.location.pathname
                var parts = path.split('/')
                parts[2] = periodId
                var newHref = parts.join('/')
                Turbolinks.visit(newHref)
                //document.location = newHref;
            }
        )


});

(function () {

    var expandedEvent = null;

    var expandEvent = function (el) {
        collapseEvent(expandedEvent)
        var parent = $(el).closest('.event-group');
        parent.find('.event-form').show();
        parent.find('.event-expand').hide();
        collapseAddEventForm();
        expandedEvent = el
    }

    var collapseEvent = function (el) {
        if (el) {
            var parent = $(el).closest('.event-group')
            parent.find('.event-form').hide();
            parent.find('.event-expand').show();
        }
    }

    var expandAddEventForm = function () {
        collapseAddEventFileForm();
        collapseEvent(expandedEvent)
        $('.add-event-form').show();
        //$('.add-event-button').parent().hide();
    }

    var collapseAddEventForm = function () {
        $('.add-event-form').hide();
        //$('.add-event-button').parent().show();
    }

    var expandAddEventFileForm = function () {
        collapseAddEventForm()
        collapseEvent(expandedEvent)
        $('.add-eventfile-form').show();
        //$('.add-event-button').parent().hide();
    }

    var collapseAddEventFileForm = function () {
        $('.add-eventfile-form').hide();
        //$('.add-event-button').parent().show();
    }

    $(document).on('click', '.event-expand-icon', function () {
        expandEvent(this)
    }).on('click', '.event-collapse', function () {
        collapseEvent(this)
    }).on('blur', '[name="event[duration]"]', function () {
        var that = $(this)
        that.val(Math.round(that.val()));
        that.trigger('keyup')
    }).on('click', '#add-event', function() {
        expandAddEventForm();
        return false;
    }).on('click', '#cancel-add-event', function() {
        collapseAddEventForm();
        return false;
    }).on('click', '#add-eventfile', function() {
        expandAddEventFileForm();
        return false;
    }).on('click', '#cancel-add-eventfile', function() {
        collapseAddEventFileForm();
        return false;
    })

})();