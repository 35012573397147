(function () {

    var activeSelect = null;

    var setQuarter = function (selected) {
        if (!selected) {
            selected = activeSelect.find('.period-select-quarter .selected')
        }
        if (selected.attr('disabled') == 'disabled') {
            // selected was move to disabed item due to year change reselect not disabled item
            selected.removeClass('selected');
            var enabled = activeSelect.find('.period-select-quarter .period-selector:not([disabled="disabled"])');
            selected = enabled.last();
            selected.addClass('selected')
        }
        if (selected.length > 0) {
            var quarter = selected.attr('data-value');
            var year = activeSelect.find('.selected-year').text();
            var periodId = year + quarter;
            selected.trigger('period-selected', [periodId, true]);
            console.log('triggered period-selected', periodId);
        }
    }

    var setYear = function (year) {
        var prev = activeSelect.find('.previous-year');
        var next = activeSelect.find('.next-year');
        var min = prev.attr('data-min');
        var max = next.attr('data-max');
        var minYear = min.substring(0, 4);
        var maxYear = max.substring(0, 4);
        activeSelect.find('.selected-year').html(year)
        prev.attr('data-value', parseInt(year) - 1);
        next.attr('data-value', parseInt(year) + 1);
        year <= minYear ? prev.removeClass('text-high').addClass('text-gray-300') : prev.removeClass('text-gray-300').addClass('text-high');
        year >= maxYear ? next.removeClass('text-high').addClass('text-gray-300') : next.removeClass('text-gray-300').addClass('text-high');
        var quarters = activeSelect.find('.period-selector');
        quarters.removeAttr('disabled');
        if (year <= minYear) {
            var minQ = min.substring(4, 6);
            quarters.filter(function () {
                return year < minYear ? true : $(this).attr('data-value') < minQ
            }).attr('disabled', 'disabled')
        } else if (year >= maxYear) {
            var maxQ = max.substring(4, 6);
            quarters.filter(function () {
                return year > maxYear ? true : $(this).attr('data-value') > maxQ
            }).attr('disabled', 'disabled')
        }
        setQuarter();
    }

    $(document).offOn('click', '.period-select .previous-year, .period-select .next-year', function () {
        var that = $(this);
        var active = that.hasClass('text-high') || that.closest('.period-admin').length > 0;
        if (active) {
            activeSelect = that.closest('.period-select');
            var year = that.attr('data-value');
            setYear(year)
        }
        return false;
    }).offOn('click', '.period-select .period-selector:not([disabled="disabled"])', function () {
        var that = $(this);
        // if (that.attr('disabled') != 'disabled') {
        activeSelect = that.closest('.period-select')
        that.addClass('selected').siblings().removeClass('selected');
        setQuarter(that);
        return false;
        //}
    }).offOn('click', '.period-admin .period-select .period-selector[disabled="disabled"]', function () {
        var that = $(this);
        var activeSelect = that.closest('.period-select')
        var quarter = that.attr('data-value');
        var year = activeSelect.find('.selected-year').text();
        var periodId = year + quarter;
        that.trigger('period-selected', [periodId, false]);
        console.log('triggered period-selected', periodId)
    });
})();