(function () {

    $(document)
        .offOn('period-selected', '#admin-regions-index .period-select', function (event, periodId, existing) {
                var newHref = "/admin/periods/" + periodId + "/regions";
                Turbolinks.visit(newHref)
                //document.location = newHref;
            }
        )
        .offOn('period-selected', '#admin-regions-edit .period-select', function (event, periodId, existing) {
                var path = document.location.pathname
            var parts = path.split('/')
parts[3] = periodId;
                var newHref = parts.join('/');
                Turbolinks.visit(newHref)
                //document.location = newHref;
            }
        )

})();