(function () {

    $(document)
        .offOn('period-selected', '#admin-events-index .period-select, #admin-events-upload .period-select', function (event, periodId, existing) {
                if (!existing) {
                    var year = periodId.substr(0, 4)
                    var q = periodId.substr(5);
                    var ref = "Q" + q + ' ' + year;
                    if (!confirm("Are you sure you want to open " + ref + "?")) {
                        return false;
                    }
                }
                var newHref = "/admin/periods/" + periodId + "/events";
                Turbolinks.visit(newHref)
                //document.location = newHref;
            }
        )

})();