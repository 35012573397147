


$(document).on(GLOBALS.readyEvent, function () {

    var el = $('#user_notifications');

    var setNotificationOptions = function() {
        var emailOpts = $('#email-options input[type="checkbox"]');
        if (el[0].checked) {
            emailOpts.removeAttr('disabled')
        } else {
            emailOpts.attr('disabled','disabled')
        }
    }

     if (el.length > 0) {
        console.log('ready for user notification stuff');
        setNotificationOptions();
        el.offOn('click',setNotificationOptions)
    }
});