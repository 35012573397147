(function () {

    var setClassificationOptions = function () {
        console.log('setting classification')
        var el =  $('#result_classification')
        if (el.length > 0) {
            var kls = $('#result_classifier').val().toLowerCase().replace(/\s/g, '_')
            el.find('option').hide();
            el.find('.' + kls).show();
            el.children().first().show();  // show blank
            var selectedOption = $(el.children()[el[0].selectedIndex]);
            if (!selectedOption.hasClass(kls)) {
                el[0].selectedIndex = 0;
            }
        }
    }


    $(document)
        .offOn('period-selected', '#admin-results-index .period-select', function (event, periodId, existing) {
                var newHref = "/admin/periods/" + periodId + "/results"
                Turbolinks.visit(newHref)
                //document.location = newHref;
            }
        )
        .on(GLOBALS.readyEvent, setClassificationOptions)
        .on('change', '#result_classifier', setClassificationOptions)

})();