$(document)
    .on("ajax:success", "[data-remote][data-update], [data-remote][data-replace]", function (event) {
        var xhr = event.detail[2];
        var that = $(this);
        var els;
        var replacing = that[0].hasAttribute('data-replace');
        var update = replacing ? that.attr('data-replace') : that.attr('data-update');
        if ((update.startsWith('#')) || (that.attr('data-closest') == 'false')) {
            els = $(update);
        } else {
            els = that.closest(update);
        }
        if (replacing) {
            els.replaceWith(xhr.responseText)
        } else {
            els.attr('data-src', xhr.responseURL);
            els.html(xhr.responseText)
            if (els.hasClass('tab')) {
                els.show().siblings().hide();
            }
        }
    })
    .on('click', '[data-search]', function () {
        var that = $(this);
        $('.entries-selection-list a').removeClass('selected');
        that.addClass('selected');
        var query = that.attr('data-search');
        $('.search-field').val(query).trigger('keyup');
        return false;
    })
    /*
    .on("ajax:success", "a[data-remote][data-update]", function (event) {
    var xhr = event.detail[2];
    var that = $(this);
    var els;
    if (that.attr('data-closest') == 'false') {
        els = $(that.attr('data-update'));
    } else {
        els = that.closest(that.attr('data-update'));
    }
    els.replaceWith(xhr.responseText);
})
     */
    .on("ajax:success", "a[data-remote][data-remove]", function (event) {
        var that = $(this);
        var els;
        if (that.attr('data-closest') == 'false') {
            els = $(that.attr('data-remove'));
        } else {
            els = that.closest(that.attr('data-remove'));
        }
        els.remove();
    }).on("ajax:success", "[data-remote][data-success-message]", function (event) {
    var that = $(this);
    alert(that.attr('data-success-message'));
}).on("ajax:success", "[data-remote][data-success-goto]", function (event) {
    var that = $(this);
    document.location = that.attr('data-success-goto');
}).on('click', "[data-toggle]", function () {
    var that = $(this);
    var selector = that.attr('data-toggle');
    $(selector).toggleClass(that.attr('data-toggle-class'));
    return false;
}).on('click', '[data-href] [type="checkbox"]', function (e) {
    e.stopPropagation(); // it's a selection checkbox we don't want the event to bubble to open the row
}).on('click', '[data-href]', function (e) {
    var target = $(e.target);
    if (target.closest('a,button,input,select').length > 0) return true;
    if ((target.prop('tagName') == 'LABEL') && (target.attr('for'))) return true;  //was a click on label will repropagate with checkbox
    console.log('event', e)
    var that = $(this);
    var href = that.attr('data-href');
    var tb = that.closest('[data-turbolinks]');
    var useTurbolinks = tb.attr('data-turbolinks') == 'false' ? false : true;
    useTurbolinks ? Turbolinks.visit(href) : document.location = href;
    return false;
}).on('change', '[data-submit-on-change="true"]', function () {
    var that = $(this);
    var form = that.closest('form');
    form.find('.submit-button').click();
})

$(document).on(GLOBALS.readyEvent, function () {
    console.log('here', $('form[data-validate="true"]').length)
    $('form[data-validate="true"]').each(function () {
        var that = $(this)
        that.validate({
            errorPlacement: function (error, element) {
                error.insertBefore($('label[for="' + element.attr('id') + '"]'));
            }
        })
    })
    $('input[data-validate-equalto]').each(function () {
        var that = $(this)
        that.rules('add', {
            equalTo: that.attr('data-validate-equalto')
        })
    })

    $('[data-role=tags]').each(function(){
        var that = $(this);
        var hints = that.attr('data-tag-hints').split(',');
        that.tagify({
            whitelist: hints,
            dropdown: {
                enabled: 0
            }
        })
    });

});

$(document).on('change','[type="file"]',function(e) {
    var that = $(this);
    var successMsg = that.parent().find('.success')
    successMsg.html(this.files.item(0).name)
})