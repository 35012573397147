(function () {

    $(document)
        .offOn('period-selected', 'body.public_reports .period-select', function (event, periodId, existing) {
                var loc = document.location.pathname;
                var parts = loc.split('/');
                parts[2] = periodId;
                var newHref = parts.join('/');
                Turbolinks.visit(newHref)
                //document.location = newHref;
            }
        )

})();