// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "application/jquery-ui"
import "application/jquery-extensions"
import "stylesheets/application"
import "application/jquery.validate"
import "application/jQuery.tagify.min"
import "application/globals"
import "application/period-select"
import "application/application"
import "application/events"
import "application/admin_venue"
import "application/admin_events"
import "application/admin_pending"
import "application/admin_regions"
import "application/admin_results"
import "application/event_file"
import "application/report"
import "application/users"
import "application/public_reports"
import "application/cookie"
import "application/multi-record-select"
import "application/search-via-cookie"
import "application/column_sort"
import "application/pusher"
//import "application/pureJSCalendar"
//import "application/datepicker"
require("trix")
require("@rails/actiontext")