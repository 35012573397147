(function () {

    var defaultRegionIds = null

    $(document).offOn('change', '#venue_local_authority_id', function (e) {
        // first check if weighting region is a non-default one
        var that = $(this);
        var sel = that.find(":selected")
        $('#venue_rto').val(sel.attr('data-rto'))
        var wr = $('#venue_region_id')
        var regionId = wr.val();
        if (!defaultRegionIds) {
            var defaultRegionIds = that.find('option').map(function () {
                return $(this).attr('data-region-id')
            }).toArray()
        }
        if (defaultRegionIds.indexOf(regionId) >= 0) {
            wr.val(sel.attr('data-region-id'));
        }
    })

})();